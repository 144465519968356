import React from 'react'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'

const Container = ({
  children,
  className,
  style,
  id,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { useSection } = useContentfulResources()
  const Element = useSection ? 'section' : 'div'

  return (
    <Element className={className} style={style} id={id} {...rest}>
      {children}
    </Element>
  )
}

export default Container
