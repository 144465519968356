import React, { useState, useRef, useEffect } from 'react'
import Button, { ButtonPropTypes } from '../Button/Button'
import { HubspotEmbeddedForm } from '../MarketoFormButtonBlock/HubspotEmbeddedForm'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Icon } from '../Icon/Icon'
import Fullscreen from '../HeroSlider/Fullscreen'
import isDarkColor from '../../helpers/isDarkColor'
import { Router, useRouter } from 'next/router'

interface HubspotFormButtonBlockProps {
  portalId: string
  formId: string
  title?: string
  buttonLabel: string
  buttonColor?: string
  style?: ButtonPropTypes['style']
  size?: ButtonPropTypes['size']
  icon?: string
  textColor?: string
  hideIconMobile?: boolean
  mobileStyle?: 'hideIcon' | 'iconOnly'
  textColour?: string
  buttonHoverColor?: string
  formInstanceId?: string
  customClass?: string
  variation?: 'text' | 'button'
  showOnMobileOnly?: boolean
  mobileWidth?: 'Full' | 'Auto'
  verifiedslug?: string
  verifyEmail?: boolean
}

const HubspotFormButtonBlock = ({
  portalId,
  formId,
  title,
  buttonLabel,
  buttonColor,
  style,
  size,
  icon,
  mobileStyle,
  textColor,
  buttonHoverColor,
  formInstanceId,
  customClass,
  showOnMobileOnly,
  variation = 'button',
  mobileWidth,
  verifiedslug,
  verifyEmail,
}: HubspotFormButtonBlockProps) => {
  const [showModal, setShowModal] = useState(false)
  const ref = useRef(null)
  const router = useRouter()
  const isDownload = router.query.download

  useEffect(() => {
    if (verifyEmail && isDownload) {
      window.open(verifiedslug, '_blank')
    }
  }, [isDownload, verifiedslug])

  const handleClick = () => {
    if (isDownload && verifyEmail) {
      // open link in new tab
      window.open(verifiedslug, '_blank')
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    const body = document?.body
    if (body) {
      body.style.overflowY = showModal ? 'hidden' : 'auto'
    }
  }, [showModal])

  if (!textColor && buttonColor) {
    textColor = isDarkColor(buttonColor) ? 'white' : 'black'
  }

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowModal(false) // close modal when user click outside the form
    }
  }

  useEffect(() => {
    const element = ref?.current
    element?.addEventListener('mousedown', handleClickOutside)
    return () => {
      element?.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <a className={`${mobileStyle === 'iconOnly' ? 'md:hidden' : 'hidden'}`} onClick={handleClick}>
        <Icon
          className="w-5 h-5 mr-2"
          name={icon}
          textColor={textColor}
          hoverColor={buttonHoverColor}
        />
      </a>

      {variation === 'button' ? (
        <Button
          id={formId}
          buttonStyle={style}
          size={size}
          onClick={handleClick}
          backgroundColor={buttonColor}
          hoverColor={buttonHoverColor}
          textColor={textColor}
          className={`${customClass} ${mobileStyle === 'iconOnly' ? 'hidden md:flex' : ''}`}
          showOnMobileOnly={showOnMobileOnly}
          mobileWidth={mobileWidth}
        >
          {icon && (
            <div className={`${mobileStyle === 'hideIcon' ? 'hidden md:block' : ''}`}>
              <Icon className="w-5 h-5 mr-2" name={icon} />
            </div>
          )}
          {buttonLabel}
        </Button>
      ) : (
        <span onClick={handleClick} className={`${customClass}`} id={formId}>
          {buttonLabel}
        </span>
      )}

      {showModal && (
        <Fullscreen>
          <div
            className={`z-[1000] fixed left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-center bg-[rgba(0,0,0,0.8)]`}
          >
            <div
              ref={ref}
              className="md:w-[35rem] bg-white animate-fade-down overflow-y-hidden rounded-xl mt-[2rem]"
            >
              <button
                className="cursor-pointer absolute top-0 right-0 m-4"
                onClick={() => setShowModal(false)}
              >
                <XCircleIcon className="w-6 h-6 text-black ml-20" />
              </button>
              <div className="overflow-y-scroll h-full w-full p-6">
                <HubspotEmbeddedForm
                  portalId={portalId}
                  formId={formId}
                  title={title}
                  isModal
                  formInstanceId={formInstanceId}
                  verifyEmail={verifyEmail}
                />
              </div>
            </div>
          </div>
        </Fullscreen>
      )}
    </>
  )
}

export default HubspotFormButtonBlock
