import React, { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { TypeModalBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { getCookie, setCookie } from 'cookies-next'
import { Icon } from '../Icon/Icon'

interface ModalBlockProps {
  entry: TypeModalBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const ModalBlock = ({ entry }: ModalBlockProps) => {
  const [hasBeenViewed, setHasBeenViewed] = useState(false)
  useEffect(() => {
    setHasBeenViewed(
      !!getCookie(`modal-${entry?.sys?.id || 'cookie'}`, {
        domain: process.env.NEXT_PUBLIC_COOKIES_DOMAIN,
      })
    )
  }, [entry?.sys?.id])
  const [showModal, setShowModal] = useState(true)

  const { title, content, backgroundColor, titleColor } = entry.fields

  const cssVars = {
    '--backgroundColor': `${backgroundColor}`,
    '--titleColor': `${titleColor}`,
  } as React.CSSProperties

  const closeModal = () => {
    setCookie(`modal-${entry?.sys?.id || 'cookie'}`, { viewed: true })
    setShowModal(false)
  }

  return (
    <>
      {showModal && (
        <Dialog
          open={!hasBeenViewed}
          onClose={closeModal}
          as={'div'}
          className="z-[1001] cursor-pointer fixed inset-0 flex flex-col bg-black-80"
        >
          <Dialog.Panel className="mx-auto my-auto relative max-w-[925px] flex w-full md:w-[70%] overflow-scroll">
            <div
              style={cssVars}
              className="bg-[--backgroundColor] animate-fade-down overflow-scroll md:mt-[2rem] w-full pt-10 px-2 flex items-center flex-col gap-6"
            >
              <button
                type="button"
                onClick={closeModal}
                className="w-8 h-8 cursor-pointer gap-2 bg-[#BFCAC8] text-[#1A1A1A] border-1 flex-row px-2 py-1 text-sm border-[#BFCAC8] absolute right-3 top-3 flex items-center justify-center rounded-full transition-colors duration-300 z-10"
              >
                <Icon className="w-3 h-3" name={'Close'} />
              </button>
              <h2
                style={cssVars}
                className="text-3xl text-[--titleColor] lg:text-5xl !leading-1 font-primary text-center text-balance leading-2"
              >
                {title}
              </h2>
              <div className="w-full flex items-center justify-center font-light flex-col text-center text-balance">
                {renderDocumentToReactComponents(content)}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </>
  )
}

export default ModalBlock
