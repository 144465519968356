import { useEffect, useMemo, useState } from 'react'
import { countriesToSeeNZ, regionsToSeeNZ, SSR_COUNTRY } from '../config/locale'
import { CourseIntake, Currency, openToType } from '../types/graphql'
import { CoursePriceType, Price } from '../types/types'
import { DualPrices } from '../components/CTACard/CTACardPrice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (value: any): value is object => {
  return value && typeof value === 'object'
}

/**
 * Localise a single field (non-recursive)
 *
 * @param value
 * @param locale
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalisedField = (value: any, locale: 'en-NZ' | 'en-US') => {
  if (!isObject(value)) {
    return value
  }
  if (Object.prototype.hasOwnProperty.call(value, locale)) {
    return value[locale]
  }
  if (Object.prototype.hasOwnProperty.call(value, 'en-NZ')) {
    return value['en-NZ']
  }
  return value
}

/**
 * Recursively localise all fields
 *
 * @param fields
 * @param country
 */
export const getLocalizedFields = <T>(fields: T, country = SSR_COUNTRY): T => {
  // Localise list of components
  if (Array.isArray(fields)) {
    return fields.map(field => getLocalizedFields(field, country)) as T
  }

  // No-op
  if (!isObject(fields)) {
    return fields
  }

  // Localise object
  const localizedFields = { ...fields }
  Object.entries(fields).forEach(([key, value]) => {
    const locale = countriesToSeeNZ.includes(country) ? 'en-NZ' : 'en-US'

    const localisedValue = getLocalisedField(value, locale)
    localizedFields[key] = getLocalizedFields(localisedValue, country)
  })
  return localizedFields
}

const formatter = locale => {
  const currency = locale === 'en-NZ' ? 'NZD' : locale === 'en-AU' ? 'AUD' : 'USD'

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

type PriceFormatter = {
  priceNZ?: number
  priceAU?: number
  priceUS?: number
  country?: string
  region?: string
  includeCountryCode?: boolean
  _currency?: string
}

export const formatPrice = ({
  priceNZ,
  priceAU,
  priceUS,
  country,
  region,
  includeCountryCode,
  _currency,
}: PriceFormatter): string | undefined => {
  const currency = _currency ?? getCurrency(country, region)
  switch (currency) {
    case Currency.Aud:
      return priceAU > 0
        ? `${formatter('en-AU').format(Math.ceil(priceAU / 100))}`
        : priceAU === 0
        ? Price.FREE
        : undefined

    case Currency.Nzd:
      return priceNZ > 0
        ? `${formatter('en-NZ').format(Math.ceil(priceNZ / 100))}`
        : priceNZ === 0
        ? Price.FREE
        : undefined

    default:
      return priceUS > 0
        ? `${formatter('en-US').format(Math.ceil(priceUS / 100))}`
        : priceUS === 0
        ? Price.FREE
        : undefined
  }
}

type useDualPricesProps = {
  country?: string
  _currency?: string
  courseType?: string
  priceAUOnline: number
  priceAUOnshore: number
  priceNZDomestic: number
  priceNZOnshore: number
  priceUSOnline: number
  priceUSOnshore: number
  includeCountryCodeOverride?: boolean
  publicIntakes?: CourseIntake[]
}

export const useDualPrices = ({
  country,
  _currency,
  priceAUOnline,
  priceAUOnshore,
  priceNZDomestic,
  priceNZOnshore,
  priceUSOnline,
  priceUSOnshore,
  publicIntakes,
}: useDualPricesProps) => {
  const currency = _currency ?? getCurrency(country)

  const isDisabledForInternational = useMemo(
    () =>
      publicIntakes?.every(intake => {
        return intake.courseEdition.openTo === openToType.DOMESTIC_ONLY
      }),
    [publicIntakes]
  )

  const isDisabledForDomestic = useMemo(
    () =>
      publicIntakes?.every(intake => {
        return intake.courseEdition.openTo === openToType.INTERNATIONAL_ONLY
      }),
    [publicIntakes]
  )

  const allPrices = useMemo(
    () => ({
      [Currency.Usd]: [
        {
          price: formatPrice({
            priceUS: priceUSOnline,
            _currency: Currency.Usd,
          }),
          location: CoursePriceType.ONLINE,
          currency: Currency.Usd,
          disabled: isDisabledForInternational || !priceUSOnline,
        },
        {
          price: formatPrice({
            priceUS: priceUSOnshore,
            _currency: Currency.Usd,
          }),
          location: CoursePriceType.ONSHORE,
          currency: Currency.Usd,
          disabled: isDisabledForInternational || !priceUSOnshore,
        },
      ],
      [Currency.Aud]: [
        {
          price: formatPrice({
            priceAU: priceAUOnline,
            _currency: Currency.Aud,
          }),
          location: CoursePriceType.ONLINE,
          currency: Currency.Aud,
          disabled: isDisabledForInternational || !priceAUOnline,
        },
        {
          price: formatPrice({
            priceAU: priceAUOnshore,
            _currency: Currency.Aud,
          }),
          location: CoursePriceType.ONSHORE,
          currency: Currency.Aud,
          disabled: isDisabledForInternational || !priceAUOnshore,
        },
      ],
      [Currency.Nzd]: [
        {
          price: formatPrice({ priceNZ: priceNZDomestic, _currency: Currency.Nzd }),
          location: CoursePriceType.DOMESTIC,
          currency: Currency.Nzd,
          disabled: isDisabledForDomestic,
        },
        {
          price: formatPrice({
            priceNZ: priceNZOnshore,
            _currency: Currency.Nzd,
          }),
          location: CoursePriceType.INTERNATIONAL,
          currency: Currency.Nzd,
          disabled: isDisabledForInternational || !priceNZOnshore,
        },
      ],
    }),
    [
      isDisabledForInternational,
      priceAUOnline,
      priceAUOnshore,
      priceNZDomestic,
      priceNZOnshore,
      priceUSOnline,
      priceUSOnshore,
    ]
  )

  const prices = useMemo(
    () =>
      filterPrices(allPrices[currency]) as {
        price: number
        location: CoursePriceType
        currency: Currency
        disabled?: boolean
      }[],
    [allPrices, currency]
  )

  const locationTypes = useMemo(
    () =>
      (
        allPrices[currency] as {
          price: number
          location: CoursePriceType
          currency: Currency
          disabled?: boolean
        }[]
      )?.map(priceOption => {
        if (!priceOption) {
          return null
        }
        return {
          id: priceOption?.location,
          value: `${
            priceOption?.location === CoursePriceType.ONSHORE
              ? 'New Zealand'
              : priceOption?.location
          } ${
            [CoursePriceType.DOMESTIC, CoursePriceType.INTERNATIONAL].includes(
              priceOption?.location as CoursePriceType
            )
              ? 'Student'
              : priceOption?.location === CoursePriceType.ONSHORE
              ? '(on campus)'
              : ''
          }`,
          url: utf8ToBase64(priceOption?.location),
          available: !priceOption?.disabled,
        }
      }),
    [allPrices, currency]
  )

  const [locationType, setLocationType] = useState(locationTypes?.[0])

  useEffect(() => {
    if (locationTypes && locationTypes.length > 0) {
      setLocationType(locationTypes[0])
    }
  }, [locationTypes])

  return { prices, currency, locationTypes, locationType, setLocationType }
}

const utf8ToBase64 = (utf8String: string) => {
  const buffer = Buffer.from(utf8String, 'utf8')
  return buffer.toString('base64')
}

const filterPrices = (
  prices: {
    price?: number
    location: CoursePriceType
    currency: string
  }[]
) => {
  const filteredPrices: DualPrices = []
  if (prices?.[0]?.price !== null && prices?.[0]?.price !== undefined) {
    filteredPrices.push(prices[0])
  }
  if (prices?.[1]?.price !== null && prices?.[1]?.price !== undefined) {
    filteredPrices.push(prices[1])
  }
  return filteredPrices
}

export const getCurrency = (country: string, region?: string): string => {
  switch (true) {
    case countriesToSeeNZ.includes(country):
    case regionsToSeeNZ.includes(region):
      return Currency.Nzd

    case country === 'AU':
      return Currency.Aud

    default:
      return Currency.Usd
  }
}

type CoursePriceFormatter = {
  priceAUOnline?: number
  priceAUOnshore?: number
  priceNZDomestic?: number
  priceNZOnshore?: number
  priceUSOnline?: number
  priceUSOnshore?: number
  country: string
  region?: string
  locationType?: string
}

export const getCoursePriceByCurrency = ({
  priceAUOnline,
  priceAUOnshore,
  priceNZDomestic,
  priceNZOnshore,
  priceUSOnline,
  priceUSOnshore,
  country,
  region,
  locationType,
}: CoursePriceFormatter) => {
  const currency = getCurrency(country, region)

  switch (currency) {
    case Currency.Aud: {
      const priceAU = locationType === CoursePriceType.ONLINE ? priceAUOnline : priceAUOnshore
      return priceAU > 0 ? Math.ceil(priceAU / 100) : priceAU
    }

    case Currency.Nzd: {
      const priceNZ = locationType === CoursePriceType.DOMESTIC ? priceNZDomestic : priceNZOnshore
      return priceNZ > 0 ? Math.ceil(priceNZ / 100) : priceNZ
    }

    default: {
      const priceUS = locationType === CoursePriceType.ONLINE ? priceUSOnline : priceUSOnshore
      return priceUS > 0 ? Math.ceil(priceUS / 100) : priceUS
    }
  }
}

type EventPriceFormatter = {
  priceAU: number
  priceNZ: number
  priceUS: number
  country: string
  region?: string
}

export const getEventPriceByCurrency = ({
  priceAU,
  priceNZ,
  priceUS,
  country,
  region,
}: EventPriceFormatter) => {
  const currency = getCurrency(country, region)
  switch (currency) {
    case Currency.Aud:
      return priceAU > 0 ? Math.ceil(priceAU / 100) : priceAU

    case Currency.Nzd:
      return priceNZ > 0 ? Math.ceil(priceNZ / 100) : priceNZ

    default:
      return priceUS > 0 ? Math.ceil(priceUS / 100) : priceUS
  }
}
