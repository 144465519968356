import React from 'react'
import { TypeNavigationMenu, TypeResourceSet } from '../../types/contentful'
import { isContentfulType } from '../../lib/contentful'
import FooterGroup from './FooterGroup'
import FooterSocialIcons from './FooterSocialIcons'
import Link from 'next/link'
import isDarkColor from '../../helpers/isDarkColor'
import CardHighlightContentBlockInner from '../CardHighlightContentBlock/CardHighlightContentBlockInner'
import SVG from 'react-inlinesvg'

type FooterDefaultProps = {
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  colour?: string
  bgColourClass?: string
  customClass?: string
}

const FooterDefault = ({ menu, colour, bgColourClass, customClass }: FooterDefaultProps) => {
  const { footerInfo, lightLargeLogo, darkLargeLogo, footerCopyright } = menu?.fields ?? {}
  const resources = [...(menu?.fields.resources || [])]
  const social =
    resources[resources.length - 1]?.fields?.title === 'Social' ? resources.pop() : undefined
  let containerClasses = customClass ? customClass : 'p-10'
  if (!bgColourClass) {
    if (!colour) containerClasses += ' bg-primary-brand '
    if (colour && isDarkColor(colour)) containerClasses += ' dark'
  } else {
    containerClasses += ` ${bgColourClass}`
  }

  const logoImage =
    colour && isDarkColor(colour)
      ? darkLargeLogo?.fields?.file?.url
      : lightLargeLogo?.fields?.file?.url

  const cssVars = {
    '--svg-color': isDarkColor(colour) ? 'white' : 'black',
  } as React.CSSProperties

  return (
    <footer className={containerClasses} style={{ background: colour }}>
      <div className="mx-auto container px-6 dark:text-white">
        <div className="flex flex-wrap justify-center md:justify-between md:gap-6 border-b-[1px] border-secondary-ui-200 dark:border-white pt-12">
          <Link href={'/'} className="block md:hidden">
            <img
              src={logoImage}
              alt={lightLargeLogo?.fields?.title}
              className="block md:hidden max-h-10 w-auto mb-12"
            />
          </Link>
          {!!footerInfo && footerInfo?.content[0].data.target && (
            <CardHighlightContentBlockInner entry={footerInfo?.content[0].data.target} />
          )}
          {resources?.map(menuItem => {
            if (
              isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                menuItem,
                'navigationMenu'
              )
            ) {
              return <FooterGroup key={menuItem.sys.id} menu={menuItem} />
            }
          })}
        </div>

        <div className="flex py-8 flex-col md:flex-row">
          <div
            className={
              'flex item-center justify-center md:items-start md:justify-start w-full md:w-1/2 text-center md:text-left py-2'
            }
          >
            <div
              className={
                'sm:hidden md:flex flex-col gap-10 items-center md:items-start text-primary-ui-900 dark:text-white font-light text-center md:text-left'
              }
            >
              <Link href={'/'} className="">
                {darkLargeLogo?.fields?.file.contentType === 'image/svg+xml' ? (
                  <div className={`w-auto max-h-10 md:h-10`}>
                    <SVG
                      className="max-h-[2.5rem] md:max-h-[3rem] [&>*]:fill-[--svg-color]"
                      src={`https:${darkLargeLogo?.fields?.file.url}`}
                      width="50%"
                      height="100%"
                      style={cssVars}
                    />
                  </div>
                ) : (
                  <img
                    src={logoImage}
                    alt={darkLargeLogo?.fields?.title}
                    className="hidden md:block max-h-10 w-auto"
                  />
                )}
              </Link>
              {`© ${new Date().getFullYear()} ${footerCopyright}`}
            </div>
          </div>

          <div
            className={
              'flex w-full md:w-1/2 items-start justify-center md:items-start md:justify-end text-center md:text-right py-2'
            }
          >
            {isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
              social,
              'navigationMenu'
            ) && <FooterSocialIcons menu={social} />}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterDefault
