import {
  TypeArticle,
  TypeButton,
  TypeFeaturedContentBlock,
  TypeFeaturedContentBlockItem,
  TypeMarketoFormButtonBlock,
  TypePerson,
} from '../../types/contentful'
import React, { useMemo, useRef } from 'react'
import { Icon } from '../Icon/Icon'
import Button from '../Button/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'

import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'
import { mergeItemFields } from '../../helpers/mergeItemFields'
import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import { buildSwiperDefaultProps } from '../slider.constants'
import { RenderCard } from '../Card/RenderCard'
import Container from '../Container/Container'
import classNames from 'classnames'
import { ICourseExtended, IEventExtended } from '../../types/types'

const FeaturedContentBlockDefault = ({
  preTitle,
  title,
  CTALink,
  CTALabel,
  featuredContentBlockItems,
  colour,
  align,
  description,
  backgroundShape,
}: TypeFeaturedContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']) => {
  const { courses, events } = useContentfulResources()

  const swiperRef = useRef<SwiperCore>()

  // Update fields with data from LMS
  const populatedFeaturedContentBlockItems = (
    featuredContentBlockItems as TypeFeaturedContentBlockItem<
      'WITHOUT_UNRESOLVABLE_LINKS',
      string
    >[]
  )?.map(featuredContentBlockItem => ({
    ...featuredContentBlockItem,
    fields: {
      ...featuredContentBlockItem.fields,
      item: featuredContentBlockItem?.fields?.item
        ? mergeItemFields(featuredContentBlockItem.fields.item, courses, events)
        : null,
    },
  })) as TypeFeaturedContentBlockItem<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  const isDark = colour && isDarkColor(colour)
  const containerClasses = classNames(
    'full-width py-3 md:py-6 lg:py-12',
    isDark && 'text-white dark'
  )
  const itemAlignment = useMemo(() => {
    switch (align) {
      case 'Left':
        return 'justify-start'
      case 'Center':
        return 'justify-center'
      case 'Right':
        return 'justify-end'
      default:
        return 'justify-start'
    }
  }, [align])
  const backgroundStyle = useMemo(() => {
    switch (backgroundShape) {
      case 'Bolt':
        return 'bolt-wide'
      case 'Bubble':
        return 'bubble-wide'
      case 'Cube':
        return 'cube-wide'
      case 'Tab':
        return 'tab-wide'
      default:
        return ''
    }
  }, [backgroundShape])

  return (
    <Container
      className={containerClasses}
      style={backgroundShape ? {} : { backgroundColor: colour }}
    >
      <div className={classNames('container mx-auto', backgroundShape && 'mb-3')}>
        <div
          className={classNames(
            'absolute h-[90%] lg:h-[88%] w-full container mx-auto top-20 lg:top-28 left-0 bottom-16 sm:bottom-6 right-0 -z-10',
            backgroundStyle
          )}
          style={backgroundShape ? { backgroundColor: colour } : {}}
        />
        <div className="flex items-end justify-between mb-9 sm:mb-12">
          <div className={`-mb-8 w-full`}>
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType={`h2`}
              align={align ? align.toLowerCase() : 'left'}
              size={`5xl`}
              description={description}
            />
          </div>

          {/* Hidden on Mobile */}
          {CTALink && CTALabel && (
            <Button className="hidden sm:flex flex-shrink-0" buttonStyle="outline" href={CTALink}>
              {CTALabel}
              <Icon className="ml-1 w-4 h-4" name="ArrowRight" />
            </Button>
          )}
        </div>

        {/* For devices greater than LG */}
        <div className={`hidden lg:flex overflow-hidden gap-x-8 ${itemAlignment}`}>
          {populatedFeaturedContentBlockItems &&
            populatedFeaturedContentBlockItems.length > 0 &&
            populatedFeaturedContentBlockItems.map((item, index) => {
              return (
                index <= 3 && (
                  <div className="lg:w-[23%]" key={index}>
                    <RenderCard
                      key={`${item?.sys?.id}-${index}`}
                      item={
                        item?.fields?.item as
                          | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
                          | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
                          | ICourseExtended
                          | IEventExtended
                      }
                      size={item?.fields?.size}
                      shape={item?.fields?.shape}
                      type={item?.fields?.type}
                      button={
                        item?.fields?.ctaButton as
                          | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
                          | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                      }
                      externalLink={item?.fields?.externalLink}
                    />
                  </div>
                )
              )
            })}
        </div>

        {/* For mobile/tablet */}
        <div className="lg:hidden">
          <Swiper
            {...buildSwiperDefaultProps({
              horizontalClass:
                'absolute !flex justify-center z-40 top-1 left-1/2 transform -translate-x-1/2 !h-fit',
            })}
            onBeforeInit={swiper => {
              swiperRef.current = swiper
            }}
            slidesPerView={'auto'}
            breakpoints={{
              768: {
                slidesPerView: 2,
                ...(populatedFeaturedContentBlockItems?.length % 2 === 0
                  ? { slidesPerGroup: 2 }
                  : { slidesPerGroupAuto: true }),
              },
            }}
            effect="slide"
            key={`swiper-featured-content-block-${title}`}
            loop={false}
          >
            <div className="mt-12">
              {populatedFeaturedContentBlockItems?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="mt-14 mb-5">
                    {/* For mobile all the sizes are "Small Square" */}
                    <RenderCard
                      item={
                        item.fields.item as
                          | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
                          | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
                          | ICourseExtended
                          | IEventExtended
                      }
                      size="Small Square"
                      shape={item.fields.shape}
                      type={item?.fields?.type}
                      externalLink={item?.fields?.externalLink}
                    />
                  </SwiperSlide>
                )
              })}
            </div>
            <Icon
              name="ArrowLeft"
              className="cursor-pointer absolute top-0 left-1/2 -translate-x-14 z-50"
              onClick={() => swiperRef.current?.slidePrev()}
            />
            <Icon
              name="ArrowRight"
              className="cursor-pointer absolute top-0 left-1/2 translate-x-10 z-50"
              onClick={() => swiperRef.current?.slideNext()}
            />
          </Swiper>
          {CTALink && CTALabel && (
            <div className="flex justify-end">
              <Button className="sm:hidden" buttonStyle="outline" href={CTALink}>
                {CTALabel}
                <Icon className="ml-1 w-4 h-4" name="ArrowRight" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default FeaturedContentBlockDefault
