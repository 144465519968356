import React from 'react'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { Icon } from '../Icon/Icon'
import { Document } from '@contentful/rich-text-types'

interface TopBannerProps {
  topBannerText: Document
  setShowTopBanner?: (value: boolean) => void
}

const TopBanner = ({ topBannerText, setShowTopBanner }: TopBannerProps) => {
  return (
    <div id="top-banner" className="full-width bg-primary-brand-600 text-black py-3 bottom-0 px-5">
      <div className="flex content-between items-center gap-3">
        <div className="text-center w-full [&>p]:mb-0 [&>p>a]:text-black">
          {renderDocumentToReactComponents(topBannerText)}
        </div>
        <Icon
          name="Close"
          className="w-3 h-3 cursor-pointer"
          onClick={() => setShowTopBanner(false)}
        />
      </div>
    </div>
  )
}

interface BottomBannerProps {
  bottomBannerText: Document
}

const BottomBanner = ({ bottomBannerText }: BottomBannerProps) => {
  return (
    <div className="bottom-0 full-width bg-primary-brand-600 text-black text-center py-3 !absolute md:hidden [&>p]:mb-0 [&>p>a]:text-black">
      {renderDocumentToReactComponents(bottomBannerText)}
    </div>
  )
}

interface PersonalisedOfferProps {
  personalisedOfferText: Document
}

const PersonalisedOffer = ({ personalisedOfferText }: PersonalisedOfferProps) => (
  <div className="hidden md:-bottom-6 -left-2 lg:-left-8 md:w-[170px] min-h-[145px] py-8 md:absolute md:cube bg-primary-brand-600 text-black md:flex flex-col justify-center text-center [&>p]:mb-0 [&>p>a]:text-black">
    {renderDocumentToReactComponents(personalisedOfferText)}
  </div>
)

export { TopBanner, BottomBanner, PersonalisedOffer }
